import { Typography ,Box,Grid,Button,TextField,Autocomplete,Stack} from '@mui/material'
import React,{useState,useEffect,useRef} from 'react'
import { getAllCountries } from '../../Common/getCountries';
import { getClientPersonalDetails,saveClientData } from '../../Common/Client';
import arrowDown from "../../Assets/SVG/dropDownArrow.svg";
import exclamation from "../../Assets/SVG/exclamation-red.svg";
import "./ClientProfile.scss"
import CircularProgress from '@mui/material/CircularProgress';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Snackbar from '@mui/material/Snackbar';
import axios from 'axios';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {InputAdornment} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
const textFieldStyles = {
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      boxShadow: '0 -2px 10px rgba(33, 33, 33, 0.15), 0 4px 8px rgba(33, 33, 33, 0.1)', // Increased top shadow
      '& fieldset': {
        border: 'none', // Remove the border
      },
    },
  };

  
const ClientProfile = () => {


  return (
    <Stack sx={{backgroundColor:"rgba(243, 245, 247, 1)",padding:'50px',height:'100%'}}> 
    <Typography>Profile</Typography>
    <PersonalDetails/>
    
    </Stack>
  )
}

const PersonalDetails=()=>{
    const [countryList,setCountryList]=useState([]);
  const [loader,setLoader]=useState(false)
  const [openSnackbar, setOpenSnackBar] = React.useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    mobile: '',
    country: '',
    companyName: '',
    designation: '',
  });
  const [isCountryWrong,setIscountryWrong]=useState(false);

  const hanldeCountryWrong=(flag)=>{
    setIscountryWrong(flag)
  }



  const [errors, setErrors] = useState({});
  const handleFormChange=(data)=>{
    setFormData({ ...formData, countryCode: data });
    
  }
  const [activeField,setActiveField]=useState("")
  const handleInputFocus = (e) => {
    const { name } = e.target;
    if (formData[name] === "" || formData[name] === null) setActiveField(name);
    else {
      setActiveField(null);
    }
  };


  const validate = () => {
    let tempErrors = {};
    tempErrors.firstName = formData.firstName ? '' : 'First Name is required';
    tempErrors.lastName = formData.lastName ? '' : 'Last Name is required';
    tempErrors.mobile = formData.mobile && formData.mobile.length >= 7 ? '' : 'Please enter a valid mobile number';
    tempErrors.companyName = formData.companyName ? '' : 'Company Name is required';
    tempErrors.countryCode = formData.countryCode && formData?.countryCode?.length>1 ? '' : 'Country Code is required';
    tempErrors.designation = formData.designation ? '' : 'designation is required';
    tempErrors.country=formData.country?"":"Country is required"

    // if(formData.countryCode && formData.countryCode.length===1){
      setIscountryWrong(false)
    // }
   

    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleSubmit = async(e) => {
    setLoader(true)
    try{
    e.preventDefault();
    if (validate()) {
      console.log(formData,"formData")
      const res=await saveClientData(formData);
      setOpenSnackBar(true);
      handleGetClientData();
    }
    else{
      setLoader(false)
    }
  }
  catch(e){

  }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleGetClientData=async()=>{
    const data={
      id:localStorage.getItem("email")
    }
    const isClientDataPresent=await getClientPersonalDetails(data);
    setFormData(isClientDataPresent || {})
    setLoader(false)
  }

  useEffect(()=>{
    const getDropDownData=async()=>{
      try{
      const data={
        id:localStorage.getItem("email")
      }
     
      const countryList=await getAllCountries();
      setCountryList(countryList);
      const isClientDataPresent=await getClientPersonalDetails(data);
      setFormData(isClientDataPresent || {})
    }
    catch(e){

    }
      
     

    }

    getDropDownData();
  },[])
const handleClose=()=>{
  setOpenSnackBar(false);
}
const [open, setOpen] = useState(false);
    return(

      <Box
      sx={{
        backgroundColor: "#fff",
        padding: "50px",
        width: "80%",
        marginTop: "20px",
      }}
      className="client-profile-page-wrapper"
    >
          <Snackbar
  anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Set to top-right
  open={openSnackbar}
  onClose={handleClose}
  message="Details Updated Successfully"
  autoHideDuration={6000}
/>
       
        <Grid container spacing={3} sx={{ alignItems: 'stretch',maxHeight:'540px' }}>
     
      <Grid item xs={12} md={9} display="flex">
        <Box className="form-container" sx={{ width: '100%', height: '100%' }}>
          <Grid container spacing={4.5}>
            <Grid item xs={12} sm={6}>
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontWeight: 500,
                  fontSize: '16px',
                  lineHeight: '22.4px',
                   marginBottom:'10px'
                }}
              >
                First Name
              </Typography>
              <TextField
  name="firstName"
  placeholder="Name"
  value={formData.firstName}
  onChange={handleChange}
  error={!!errors.firstName}
  helperText={errors.firstName}
  fullWidth
  sx={textFieldStyles}
  onBeforeInput={(e) => {
    const regex = /^[a-zA-Z\s]*$/;
    if (!regex.test(e.data)) {
      e.preventDefault(); // Prevent input if it’s not a letter or space
    }
  }}
/>

            </Grid>
    
            <Grid item xs={12} sm={6}>
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontWeight: 500,
                  fontSize: '16px',
                  lineHeight: '22.4px',
                   marginBottom:'10px'
                   
                }}
                
              >
                Last Name
              </Typography>
              <TextField
  name="lastName"
  placeholder="Last Name"
  value={formData.lastName}
  onChange={handleChange}
  error={!!errors.lastName}
  helperText={errors.lastName}
  fullWidth
  sx={textFieldStyles}
  onBeforeInput={(e) => {
    const regex = /^[a-zA-Z\s]*$/;
    if (!regex.test(e.data)) {
      e.preventDefault(); // Prevent input if it’s not a letter or space
    }
  }}
/>


            </Grid>
    
            <Grid item xs={12} sm={6}>
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontWeight: 500,
                  fontSize: '16px',
                  lineHeight: '22.4px',
                  marginBottom: '2px',
                   marginBottom:'10px'
                }}
              >
                Mobile Number
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={5}>
                <DropDownBoxSearch
                          placeholder=""
                          options={countryList}
                          name="countryCode"
                          value={formData?.countryCode}
                          handleChange={handleFormChange}
                          handleInputFocus={handleInputFocus}
                          activeField={activeField}
                          //formError={formError}
                          hanldeCountryWrong={hanldeCountryWrong}
                          formError={!!errors.countryCode} // Pass error state
                        />
                </Grid>
    
                <Grid item xs={12} sm={7}>
                <TextField
  name="mobile"
  placeholder="Mobile Number"
  value={formData.mobile}
  onChange={(e) => {
    // Restrict to only numbers
    if (/^\d*$/.test(e.target.value)) {
      handleChange(e);
    }
  }}
  onKeyPress={(e) => {
    // Prevent non-numeric characters from being typed
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  }}
  error={!!errors.mobile && errors?.countryCode?.length === 0} // Show error if mobile error exists and country code is entered
  helperText={!!errors.mobile && errors?.countryCode?.length === 0 ? errors.mobile : ''} // Show helper text conditionally
  fullWidth
  sx={textFieldStyles}
  inputProps={{ maxLength: 16 }} // Restrict length to 16
/>



                </Grid>
                
                
              </Grid>
              {isCountryWrong?<Typography 
      variant="caption" 
      color="error" 
      sx={{ mt: 0.5, display: 'block' }}
    >
      Invalid Code
    </Typography>:""}
              { ((!!errors.mobile) && (!!errors.countryCode)) ?<Typography 
      variant="caption" 
      color="error" 
      sx={{ mt: 0.5, display: 'block' }}
    >
      Mobile code and Mobile number are required
    </Typography>:
    
    ((!!errors.countryCode)) ?
    <Typography 
      variant="caption" 
      color="error" 
      sx={{ mt: 0.5, display: 'block' }}
    >
      {formData.mobile.length >0 && formData.mobile.length<7?"Please enter valid mobile number":"Mobile code  required"}
    </Typography>
    :""}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontWeight: 500,
                  fontSize: '16px',
                  lineHeight: '22.4px',
                   marginBottom:'10px'
                }}
              >
                Country
              </Typography>
              <Autocomplete
      options={countryList || []}
      getOptionLabel={(option) => option?.name || ""}
      value={countryList.find((country) => country?.name === formData.country) || null}
      onChange={(event, value) => setFormData({ ...formData, country: value?.name || '' })}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Country"
          fullWidth
          error={!!errors.country}
          helperText={errors.country}
          sx={{
            ...textFieldStyles,
            "& .MuiInputBase-root": {
              paddingRight: "38px", // Adjust right padding
            },
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end" sx={{ position: "absolute", right: 0, padding: 0,paddingRight:'10px' }}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </InputAdornment>
            ),
          }}
        />
      )}
    />
            </Grid>
    
            <Grid item xs={12} sm={6}>
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontWeight: 500,
                  fontSize: '16px',
                  lineHeight: '22.4px',
                   marginBottom:'10px'
                }}
              >
                Company Name
              </Typography>
              <TextField
                name="companyName"
                placeholder="Company Name"
                value={formData.companyName}
                onChange={handleChange}
                error={!!errors.companyName}
                helperText={errors.companyName}
                fullWidth
                sx={textFieldStyles}
              />
            </Grid>
    
            <Grid item xs={12} sm={6} >
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontWeight: 500,
                  fontSize: '16px',
                  lineHeight: '22.4px',
                  marginBottom:'10px'
                   
                }}
              >
                Designation
              </Typography>
              <TextField
                name="designation"
                placeholder="Designation"
                value={formData.designation}
                onChange={handleChange}
                error={!!errors.designation}
                helperText={errors.designation}
                fullWidth
                sx={textFieldStyles}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    
      {/* Right Side: Illustration */}
      
    </Grid>
    
    
    <Grid item>
    
    <Button
    onClick={(e)=>{
      handleSubmit(e)
    }}
      variant="outlined"
      sx={{
        backgroundColor: 'rgba(6, 43, 110, 1)', 
        borderRadius: '8px',
        width: '140px',
        height: '44px',
        color: '#fff', 
        marginTop:'30px',
        '&:hover': {
          backgroundColor: 'rgba(6, 43, 110, 1)', // Ensures no change on hover
          borderColor: 'rgba(6, 43, 110, 1)',    // Keeps border color the same
        },
      }}
    >
      {loader?<CircularProgress sx={{color:'white'}}/> :"Update"}
    </Button>
    
    </Grid>
    
        
        </Box>
    )
}

const DropDownBoxSearch = ({
  placeholder,
  options,
  value,
  name,
  handleChange,
  handleInputFocus,
  activeField,
  formError,
  isAcceptingCustom = false,hanldeCountryWrong
}) => {
  const [viewOptions, setViewOptions] = useState(false);
  const [dropDownOptions, setDropdownOptions] = useState([...options]);
  const [itemValues, setItemValues] = useState(value);
  const componentRef = useRef(null);

  const dropDownChange = (item) => {
    setViewOptions(false);
    setItemValues(item?.dialCode || item);
    handleChange(item?.dialCode || item);
  };

  const handleDivFocus = () => {
    handleInputFocus({ target: { name } });
  };

  const handleDropDownOptions = (e = "") => {
    setItemValues(e);
    const filteredOptions = options.filter((option) =>
      option?.dialCode?.toLowerCase().includes(e.toLowerCase())
    );
    setDropdownOptions([...filteredOptions]);
    handleChange(e);
  };

  const handleKeyPress = (e) => {
    if ((e.key === 'Enter' || e.key === 'Return') ) {
      e.preventDefault(); // Prevent default form behavior
      setViewOptions(false); // Close the dropdown
      handleChange(itemValues); // Set the typed value
    }
  };

  useEffect(() => {
    handleDropDownOptions(value);
  }, [options]);

  useEffect(() => {
    setItemValues(value);
  }, [value]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (componentRef.current && !componentRef.current.contains(event.target)) {
        setViewOptions(false);
        // Accept the typed value if it’s not in the dropdown and isAcceptingCustom is true
        if (isAcceptingCustom && !dropDownOptions.find((option) => option?.dialCode === itemValues)) {
          handleChange(itemValues);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [itemValues, dropDownOptions, isAcceptingCustom]);

  return (
    
    <div className="dropDown-wrapper" ref={componentRef}>
      <div
        tabIndex={0}
        className={`placeholder ${formError ? "" : ""}`}
        onClick={() => setViewOptions(!viewOptions)}
      >
        <div className="value">
        <input
  type="text"
  placeholder={placeholder}
  value={itemValues}
  onChange={(e) => {
    // Ensure first character is '+' and allow only digits afterward
    const value = e.target.value;
    const regex = /^\+[0-9]*$/;

    if (regex.test(value) || value === "+") {
      handleDropDownOptions(value);
      hanldeCountryWrong(false);
    } else {
      hanldeCountryWrong(true);
    }
  }}
  onFocus={handleDivFocus}
  onKeyDown={handleKeyPress}
/>


        </div>
        <div className="arrow">
          <KeyboardArrowDownIcon
            sx={{
              color: '#707070',
              transform: viewOptions ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          />
        </div>
      </div>
      {formError?.[name] ? (
      <div className="missing">
        <div>
          <img src={exclamation} alt="" />
        </div>
        <div className="mandatory2">
          {name} is missing
        </div>
      </div>
    ) : (
      activeField === name && <div className="mandatory"></div>
    )}

      {viewOptions && (
        <div className="options-wrapper">
          {dropDownOptions?.map((item, index) => (
            <div
              key={index}
              onClick={() => dropDownChange(item?.dialCode)}
            >
              {item?.dialCode}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};




export default ClientProfile