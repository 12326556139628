import "./ClientLogin2.scss";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import {TextField,Typography} from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import ClientDataForm from "../ClientDataForm/ClientDataForm"

import { useCookies } from "react-cookie";
import axios from "axios";
import ErrorIcon from "@mui/icons-material/Error";
import { afterLogin,afterClinetSignup } from "../../Common/flowAfterLogin";
import { resolve } from "../../Common/resolve";
import { TopLogo } from "../../Components/topLogo/topLogo";
import laptopImage from "../../Assets/candidate_login/laptop.svg";
import { Layout } from "../../Components/Common/Layout/Layout";
import { SeoTags } from "../../Components/Common/SeoTags/SeoTags";
import ClientFirstLoginTour from "../../Components/ClientPage/ClientFirstLoginTour/ClientFirstLoginTour";
import ClientSearchBar from "../../Components/ClientPage/ClientSearchBar/ClientSearchBar";
import CandidateCard from "../../Components/ClientPage/CandidateCard/CandidateCard";
import { TopLogoClient } from "../../Components/topLogo/topLogoClient";
import {Grid,Box} from "@mui/material"

const seoData = {
  title: "Zeero Developer Login ",
  desc: "Sign in to your Zeero developer account. Update your profile, complete your vetting process, access project, and unlock exciting opportunities. ",
  canonical: "login",
};

export const ClientLogin = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 650) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }

    window.addEventListener("resize", handleResize);
    handleResize(); // Call once initially
    // console.log("deviceType==>", deviceType());

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
    <Box className="client-login-wrapper">
      <SeoTags seoData={seoData} />
      <div className="login-wrapper">
        {isMobile ? (
          <MobileScreen />
        ) : (
            <div className="register-main">
            <Grid sx={{marginTop:"0px"}} container>
    
           <Grid lg={6} item>
            <LeftImage />
            </Grid>
            <Grid  lg={6}item>
            <LoginWrapper/>
            </Grid>

            </Grid>
      </div>
        )}
      </div>
      </Box>
    </>
  );
};

const MobileScreen = () => {
  return (
    <Layout>
      <div className="mobile-ui">
        <div className="laptop">
          <img src={laptopImage} alt="" />
        </div>
        <div className="desc">
          Please login through the Desktop as the mobile version is unavailable.
        </div>
        <button className="okay" onClick={() => (window.location.href = "/")}>
          Okay
        </button>
      </div>
    </Layout>
  );
};


const LeftImage = () => {
    return (
      <div className="left-image">
        <div className="item-content">
          <TopLogoClient />
          <div className="logo-candidate">
            <img src="./images/ClientLeftImage.svg" alt="" />
            <div>
          <Typography
    sx={{
      color: "#212121",
      fontFamily: "Poppins",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
      textAlign:'center',
      marginTop:'32px'
    }}
  >
    Your Gateway to Elite Talent!!
  </Typography>
  </div>
          </div>
         
  
        
        </div>
      </div>
    );
  };

const LoginWrapper = () => {
  const [cookies, setCookie] = useCookies(["user"]);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState(cookies?.username ? cookies.username : "");
  const [password, setPassword] = useState(
    cookies?.password ? cookies.password : ""
  );

  const [remember, setRemember] = useState(
    cookies?.remember ? cookies.remember : false
  );
  const [viewError, setViewError] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleViewError = () => {
    setViewError(true);
    // setTimeout(() => {
    //   setViewError(false);
    // }, 5000);
  };

  const handleLoginClick = async (e) => {
    e?.preventDefault();
    setViewError(false);
    try {
      const data = {
        officialMail: email,
        password: password,
      };
      const urlEndpoint = "/candidate/authentication/user/client/signin";
      const resPromise = axios.post(urlEndpoint, data).then((res) => res.data);
      // console.log("resPromise", resPromise);
      const res = await resolve(resPromise);
      if (res?.error?.response?.data) {
        throw new Error(res.error?.response?.data);
        console.log("check1")
      }
      // console.log("handleLoginClick ==>", res?.error?.response?.data);
      if (remember) rememberCookie();
      if (res) {
        // sessionStorage.setItem("email", email);
        localStorage.setItem("email", email);
        afterClinetSignup();

      } else {
        throw new Error("Something went wrong");
        console.log("check2")
      }
    } catch (err) {
      
      handleViewError();
      console.log("check3")
    
    }
  };

  const rememberCookie = () => {
    setCookie("username", email, {
      path: "http://localhost:3000",
    });
    setCookie("password", password, {
      path: "http://localhost:3000",
    });
    setCookie("remember", remember, {
      path: "http://localhost:3000",
    });
  };

  useEffect(() => {
    // Replace with your actual API call
    const checkAuthentication = async () => {
      try {
        const data = {
          email: localStorage.getItem("email"),
        };
        const response = await axios.post("/validate/auth", data);
        const data1 = response?.data;
        if (data1?.isAuthenticated) navigate("/client/searchtalent");
        // Assuming the API returns an object with an isAuthenticated field
      } catch (error) {
        console.error("Error checking authentication:==>", error);
      }
    };

    checkAuthentication();
  }, []);

  return (
    <div className="right-container-wrapper">
          <div className={`right-side-main `}>
          <div className={`right-side-main-2`}>
                <div className="right-item">
                 
                  <div className="title">Login</div>

                  <div className="form-wrapper">
                    <form onSubmit={handleLoginClick}>
                      <div className="password-wrapper">
                      <label className="label">Email</label>
                      <TextField
                    
                          type="text"
                          placeholder="Enter your email"
                          fullWidth
                          className="content-input"
                          required
                          name="email"
                          autoComplete="false"
                          style={{
                            backgroundColor: "white",
                            padding: "0px",
                            outline: "none",
                          }}
                          sx={{
                            '.MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'transparent', // Initial border color
                              },
                              '&:hover fieldset': {
                                borderColor: 'transparent', // No border on hover
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'transparent', // No border on focus
                              },
                              height: '50.4px', // Set the height here
                            },
                          }}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          
                        />
                        <label className="label">Password</label>
                      

                       <TextField
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter your password"
                          fullWidth
                          className="content-input"
                          required
                          name="password"
                          autoComplete="false"
                          style={{
                            backgroundColor: "white",
                            padding: "0px",
                            outline: "none",
                          }}
                          sx={{
                            '.MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'transparent', // Initial border color
                              },
                              '&:hover fieldset': {
                                borderColor: 'transparent', // No border on hover
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'transparent', // No border on focus
                              },
                              height: '50.4px', // Set the height here
                            },
                          }}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={handleTogglePassword}>
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                         {viewError && (
              <div className="errorBox">
                <ErrorIcon />
                <span>Invalid username or password</span>
              </div>
            )}


                       
                      </div>
                      <div>
                       
                        {/* <input
                      type="password"
                      required
                      className="content-input"
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={(e) => {
                        setSamePassword(false);
                        setConfirmPassword(e.target.value);
                      }}
                    /> */}
                    
                        
                       
                      </div>
                      <div className="actions">
            <div className="checkbox-group">
              <input
                type="checkbox"
                value={remember}
                onChange={(event) => {
                  setRemember(event.target.checked);
                }}
                autoComplete="on"
              />
              <label htmlFor="remember">Remember me?</label>
            </div>
            <div>
            <Link className="forgot-link" to={"/forgot-password"}>
              Forgot password?
            </Link>
            </div>
          </div>
                      <div style={{ width: "100%" }}>
                        <input
                          type="submit"
                          className="last-button"
                          value="Login"
                        />
                      </div>
                    </form>
                    <div className="register-link">
          Don't have an account ? <Link to={"/signup-client"}>Register</Link>
        </div>
                  </div>
                </div>
              </div>
      </div>
    </div>
  );
};
