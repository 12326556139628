import { Layout } from "../../Components/Common/Layout/Layout";
import { BackButton } from "../../Components/Common/BackButton/BackButton";
import "./CandidateInfo.scss";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import { useNavigate ,useLocation} from "react-router-dom";
import { Accordion } from "../../Components/Common/Accordion/Accordion";
import CircleIcon from "@mui/icons-material/Circle";
import Rating from "@mui/material/Rating";
import bulb from "../../Assets/SVG/bulb.svg";
import { ProgressBar } from "../../Components/Common/ProgressBar/ProgressBar";
import circleWithGreenDot from "../../Assets/SVG/circle-with-green-dot.svg";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useEffect, useState } from "react";
import { getCandidateCardInfo ,getCandidateAllInfo, getCandidateAllInfoMask} from "../../Common/candidate";
import { formatDateWithYearAndMonth,calculateTotalExperience,formatDateWithYearAndMonthExp } from "../../utils/dateUtils";
import CandidateCardListWithBlur from "../../Components/ClientPage/CandidateCard/CandidateCardListWithBlur/CandidateCardListWithBlur";
import {TableBody, Button ,TextField,Stack,TableCell,TableContainer,Paper,Table,TableHead,TableRow,Typography} from "@mui/material";
import AddIcon from '@mui/icons-material/CheckCircleOutline'; 
import CandidateInfoBlur from "./CandidateInfoBlur/CandidateInfoBlur";
import { checkUserPaidStatus } from "../../Common/Client";
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import { addRemoveShortlist } from "../../Common/Client";
import clientImage from "../../Assets/client_tour/CandidateInfoIllustration.svg"
import styles from './VettedSkillsEdit.module.scss';
const CandidateInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [userPaidStatus,setUserPaidStatus]=useState(false)
  const handleBackButton = () => {
    navigate("/client/searchtalent");
  };
  const [candidateEmail, setCandidateEmail] = useState();
  const [candidateCardInfo, setCandidateCardIndo] = useState({});
  const [candidateInformation, setCandidateInformation] = useState({});
  const [shortlisted,setShortlisted]=useState(false);

  // spaace purchase user or not
  useEffect(()=>{
    const apicall=async()=>{
      const obj={
        id:localStorage.getItem("email")
      }
       const status=await  checkUserPaidStatus(obj);
       if(!status){
        fetchCandidateCardInfoMask()
       }else{
        fetchCandidateCardInfo();
       }
      
      setShortlisted(location.state.shortlist);
       setUserPaidStatus(status);
    }
    apicall()
    
    },[])

  // /candidate/detailInfo/profile/get/save
  const fetchCandidateCardInfo = async () => {
    const data = {
      name: location.state.email,
      email:localStorage.getItem("email")
    };
    const res= await getCandidateCardInfo(data);
    const response=await getCandidateAllInfo(data)
    if (res) {
      setCandidateCardIndo(res);
    }
    if(response){
      setCandidateInformation(response)
    }
  };

  const fetchCandidateCardInfoMask = async () => {
    const data = {
      name: location.state.email,
      email:localStorage.getItem("email")
    };
    const res= await getCandidateCardInfo(data);
    const response=await getCandidateAllInfoMask(data)
    if (res) {
      setCandidateCardIndo(res);
    }
    if(response){
      setCandidateInformation(response);
    }
  };


  useEffect(() => {
    // fetchCandidateCardInfo();
    // setShortlisted(location.state.shortlist);
  }, []);
  const handleAddShortlist=async()=>{
    try{
    const data={
      "clientEmail" : localStorage.getItem("email"),
      "developerEmail" : location.state.email,
      "add" : !shortlisted
  }
    const res=await addRemoveShortlist(data);
    setShortlisted((prev)=>!prev)
}
catch(e){

}
  }
  return (
    // <Layout>
      <div className="candidate-info-main">
        <BackButton
          handleBackButton={handleBackButton}
          name={"Back"}
        />
        <div className="candidate-info-body">
          <CandidateInfoVerticalCard candidateCardInfo={candidateCardInfo} shortlisted={shortlisted} handleAddShortlist={handleAddShortlist} />
          <CandidateDetailInfo  candidateInformation={candidateInformation} userPaidStatus={userPaidStatus}/>
        </div>
      </div>
    // </Layout>
  );
};

const CandidateInfoVerticalCard = ({ candidateCardInfo ,shortlisted,handleAddShortlist}) => {
  const {
    name,
    skill,
    state,
    country,
    experienceInYears,
    industryDomain,
    availability,
    preferredJobType,
    allSkills,
  } = candidateCardInfo;

  const maskName = (fullName) => {
    // Split the name into parts (assumes "First Last" format)
   
    const nameParts = fullName?.split(' ') || "Candidate";
  
    if (nameParts?.length < 2) {
      return fullName; // If there's no space (i.e., single name), return the full name unmasked
    }
  
    const firstName = nameParts[0];
    const lastName = nameParts[1];
  
    // Mask first name, show first letter, then asterisks
    const maskedFirstName = `${firstName[0]}${'*'.repeat(firstName.length - 1)}`;
  
    // Mask last name, show first letter, then asterisks
    const maskedLastName = `${lastName[0]}${'*'.repeat(lastName.length - 1)}`;
  
    // Combine the masked parts
    return `${maskedFirstName} ${maskedLastName}`;
  };
  return (
    <div className="candidate-info-left">
      <img
        className="candidate-image"
        // src="https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
        src={clientImage}
        alt="candidate-image"
      ></img>
      <div className="name">{name}</div>
      <div className="role">{skill}</div>
      <div className="location">
        <LocationOnOutlinedIcon style={{ color: "#0B52D4" }} />
        <span>
          {state}, {country}
        </span>
      </div>
      <div className="experience">
        <WorkOutlineOutlinedIcon style={{ color: "#0B52D4" }} />
        <span>{experienceInYears}</span>
      </div>
      <div className="domain">
        <BusinessOutlinedIcon style={{ color: "#0B52D4" }} />
        <span>{industryDomain}</span>
      </div>
      <div className="availability">
        <span>Availability: </span>
        <span>{availability}</span>
      </div>
      {/* <div className="job-type">
        <span>Preferred Job Type: </span>
        <span>{preferredJobType}</span>
      </div> */}
      <div className="technologies">
        {allSkills
          ?.map((skill) => skill.skillName)
          ?.map((skill) => (
            <span>{skill}</span>
          ))}
      </div>
      {/* <button className="hire-button">Hire Archie</button> */}
      {shortlisted? 
      <Button
      variant="outlined"
      startIcon={<BookmarkAddOutlinedIcon />} // Icon on the left
      className="hire-button"
      onClick={handleAddShortlist}
      sx={{
        borderRadius: '4px',
        border: '1px solid #0B52D4',
        width: '171px',
        height: '40px',
        flexShrink: 0,
        color: '#0B52D4', // Custom text color to match the border
        textTransform: 'none', // Disable uppercase transformation
      }}
    >
      Shortlisted
    </Button>: 
      <Button
      onClick={handleAddShortlist}
      variant="outlined"
      startIcon={<BookmarkBorderOutlinedIcon />} // Icon on the left
      className="hire-button"
      sx={{
        borderRadius: '4px',
        border: '1px solid #8D8D8F',
        width: '171px',
        height: '40px',
        flexShrink: 0,
        color: '#8D8D8F', // Custom text color to match the border
        textTransform: 'none', // Disable uppercase transformation
      }}
    >
      Add To Shortlist
    </Button>
    }
    </div>
  );
};

const CandidateDetailInfo = ({candidateInformation,userPaidStatus}) => {
  const accordionItems = [
    {
      header: "Vetted Technical Skills",
      Body: () => <VettedTechnicalSkills vettedSkills={candidateInformation?.vettedTechnicalSkills || []}  />,
      isEmpty: () => !candidateInformation?.vettedTechnicalSkills?.length,
     
    },
    {
      header: "Work Experience & Project Highlights",
      Body: () => (
        <WorkExperience workData={candidateInformation?.workExperience || []} />
      ),
      isEmpty: () => !candidateInformation?.workExperience?.length,
    },
    {
      header: "Skills & Technologies",
      Body: () => (
        <Skills skills={candidateInformation?.skillsAndTechnologies?.skills || []} />
      ),
      isEmpty: () => !candidateInformation?.skillsAndTechnologies?.skills?.length,
    },
    {
      header: "Education",
      Body: () => <Education education={candidateInformation?.education || []} />,
      isEmpty: () => !candidateInformation?.education?.length,
    },
    {
      header: "Certification & Achievements",
      Body: () =><Certifications  data={candidateInformation?.certificationAndAchievements?.[0]?.certifications || []} achievements={candidateInformation?.certificationAndAchievements[0]?.achievements || []} />,
      isEmpty: () =>!( candidateInformation?.certificationAndAchievements?.[0]?.certifications?.length ||  candidateInformation?.certificationAndAchievements?.[0]?.achievements?.length )
    },
    {
      header: "Recommendations/Testimonials",
      Body: () => <Recommendations data={candidateInformation?.recommendations || []} />,
      isEmpty: () => !candidateInformation?.recommendations?.length,
    },
    {
      header: "Soft Skills",
      Body: () => <SoftSkills data={candidateInformation?.softSkills || []}/>,
      isEmpty: () => !candidateInformation?.softSkills?.length,
    },
    {
      header: "Languages",
      Body: () => <Languages data={candidateInformation?.languages || []} />,
      isEmpty: () => !candidateInformation?.languages?.length,
    },
    {
      header: "Interests",
      Body: () => (
        <Interests data={candidateInformation?.interests || []} />
      ),
      isEmpty: () => !candidateInformation?.interests?.length,
    },
  ];
  
  // Filter out empty accordion items based on the isEmpty condition
  const filteredAccordionItems = accordionItems.filter((item) => !item.isEmpty());
  

  return (
    <div className="candidate-info-right">
      <Summary summary={candidateInformation?.executiveSummary || ""} />
      <Accordion
      accordionItems={userPaidStatus ? filteredAccordionItems : filteredAccordionItems.slice(0, 3)}
      classes={{ main: "accordion-main", header: "header", body: "body" }}
    />
      {!userPaidStatus && <CandidateInfoBlur />}
      {/* {true && <CandidateInfoBlur />} */}
      <div className="share-main">
        <ShareOutlinedIcon className="share" />
        <span className="share-title"> Share with your network</span>
        <div className="social-media">
          <LinkedInIcon className="linkedin" />
          <InstagramIcon className="instagram" />
        </div>
      </div>
      
    </div>
  );
};

const Summary = ({summary}) => {
  return (
    <div className="accordion-main">
      <div className="header">
        <span>Summary</span>
      </div>
      <div className="body">
        {" "}
        <div>
         {summary?.length!==0 ?summary:` Since 2018, Archie has been working professionally in the fields he
          loves, software and data—culminating in him co-founding the Rubota
          corporation in 2017. Before that, he spent the past decade at Cornell
          University conducting scientific research specifically in statistical
          and biological physics. All in all, Matthew is an engaging, intense
          communicator with a passion for knowledge and understanding.`}
        </div>
      </div>
    </div>
  );
};

const VettedTechnicalSkills = ({ vettedSkills }) => {
  return (
    <div className={styles.vetted}>
    <TableContainer component={Paper} className={styles['table-container']}>
      <Table>
        <TableHead>
          <TableRow className={styles['table-header-row']} sx={{padding:'0px'}}>
            <TableCell className={styles['table-header']} sx={{padding:'0px'}}>Skills</TableCell>
            <TableCell className={styles['table-header']} sx={{padding:'0px'}}>Experience</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody sx={{padding:'0px'}}>
          {vettedSkills?.map((skillItem, index) => (
            <TableRow key={index} className={styles['table-row']} sx={{padding:'0px'}}>
              <TableCell sx={{padding:'0px',paddingTop:'10px'}} className={styles['table-cell']}>
              <Typography
  sx={{
    color: '#4F4F4F',
    // textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: '0.2px',
    padding:'0px'
  }}
>
  {skillItem?.skill}
</Typography>
              </TableCell>
              <TableCell sx={{padding:'0px',paddingTop:'10px'}} className={styles['table-cell']}>
              <Typography
  sx={{
    color: '#4F4F4F',
    // textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: '0.2px',
  }}
>
  {skillItem?.experience}
</Typography>
                
              </TableCell>
              <TableCell className={styles['table-cell']} />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    {/* Update Button */}
   
  </div>
  );
};



const VettedTechnicalSkillItem = ({ skillItem }) => {
  return (
    <div className="vetted-skills-item">
      <div className="skill">{skillItem?.skill}</div>
     
      <div className="experience">{skillItem?.experience || ""}</div>
    </div>
  );
};

const WorkExperience = ({workData}) => {
  return (
    <div className="work-experience-items">
      {
        workData.map((work)=>(
          <WorkExperienceItem  work={work}/>
        ))
      }
    </div>
  );
};

const WorkExperienceItem = ({work}) => {
  return (
    <div className="work-experience-item">
      <div className="item-container">
        <div className="designation-main">
          <div className="designation">
            <div className="circle-with-green-dot">
              <img src={circleWithGreenDot} alt="circle-with-green-dot"></img>
            </div>
            <span>{work?.position || ""}</span>
            <div className="dot">
              <CircleIcon style={{ color: "green", fontSize: "10px" }} />
            </div>
           { work?.startDate!==""? <span> {formatDateWithYearAndMonth(work?.startDate || "")} - {work?.endDate==="present"?"Present":formatDateWithYearAndMonth(work?.endDate || "")} ({calculateTotalExperience(work?.startDate,work?.endDate || "")})</span> : ""}
          </div>
          <div className="company">{work?.company}</div>
          <div className="location">
            <span>Location: </span>
            <span>{work?.location}</span>
          </div>
        </div>
        {work?.projects?.map((project,index)=>(
          <>
          <div className="project-main" style={{marginTop:'10px'}}>
          <div className="project-header">Project:</div>
          <div className="project-title">{project?.projectName || ""}</div>
          <div className="project-description">
           {project?.projectSummary || ""}
          </div>
        </div>
        <div className="responsibilities-main">
          <div className="responsibilities-header">
            Responsibilities & Achievements:
          </div>
          <ul className="responsibilities-list">
            { project?.responsibilities.map((highligh)=>(
               <li>{highligh}</li>
            ))
             
            }
          </ul>
        </div>
        <div className="tech-stacks">
          <div className="tech-stack-header">Technologies stack:</div>
          <div className="tech-stack-list" >
           {project?.techStack?.map((tech)=>(
            <span>{tech}</span>
           ))}
          </div>
        </div>
          </>
        ))}
        
      </div>
    </div>
  );
};

const Skills = ({skills}) => {
  return (
    <div className="skills">
      {
        skills?.map((skill)=>(
          <SkillItem skill={skill} />
        ))
      }
      
    </div>
  );
};

const SkillItem = ({skill}) => {
  return (
    <span className="skill-item">
      <span className="skill">{skill?.name}</span>
      
    </span>
  );
};

const Education = ({education}) => {
  return (
    <div className="education-certifications">
      {
        education.map((item)=>(
          <EducationCertificationItem  education={item}/>
        ))
      }
     
    </div>
  );
};

const Certifications = ({ data, achievements }) => {
  // Check if data and achievements are empty
  const hasData = data && data.length > 0;
  const hasAchievements = achievements && achievements.length > 0;

  return (
    <div className="education-certifications">
      {/* Show "No data" if both are empty */}
     
      
      {/* Render CertificationItem if data is present */}
      {hasData && data.map((item, index) => (
        <CertificationItem key={index} education={item} />
      ))}

      {/* Render Achievement if achievements are present */}
      {hasAchievements && <Achievement achievements={achievements} />}
    </div>
  );
};
const CertificationItem = ({education}) => {
  return (
    <div className="education-certification-item">
      <div className="name">
        <span className="title">
          {education?.name}
        </span>
        <div className="dot">
          <CircleIcon style={{ color: "green", fontSize: "10px" }} />
        </div>
        <span>{formatDateWithYearAndMonthExp(education?.startDate || "")} - {formatDateWithYearAndMonthExp(education?.endDate || "")}</span>
      </div>
      <div className="place">{education?.organization}</div>
      <div className="location">
        <span>Location: </span>
        <span>{education?.location}</span>
      </div>
      
    </div>
  );
};

const Achievement=({achievements})=>{
  return (
  
    <div className="responsibilities-main">
            <div className="responsibilities-header">
               Achievements:
            </div>
            <ul className="responsibilities-list">
              { achievements?.map((highligh)=>(
                 <li>{highligh}</li>
              ))
               
              }
            </ul>
          </div>
  )
  }

const EducationCertificationItem = ({education}) => {
  return (
    <div className="education-certification-item">
      <div className="name">
        <span className="title">
          {education?.degree}
        </span>
        <div className="dot">
          <CircleIcon style={{ color: "green", fontSize: "10px" }} />
        </div>
        <span>{formatDateWithYearAndMonth(education?.startDate || "")} - {formatDateWithYearAndMonth(education?.endDate || "")}</span>
      </div>
      <div className="place">{education?.institution}</div>
      <div className="location">
        <span>Location: </span>
        <span>{education?.location}</span>
      </div>
    </div>
  );
};

const Recommendations = ({data}) => {
  return (
    <div className="recommendations">
      {data?.map((item)=>(
         <RecommendationItem recommed={item} />
      ))}
     
      
    </div>
  );
};

const RecommendationItem = ({recommed}) => {
  return (
    <div className="recommendation-item">
      <div className="header">{recommed?.recommenderRole || ""}</div>
      <div className="name">{recommed?.recommenderName || ""}</div>
      <div className="description">
        {recommed?.recommendationNote || ""}
      </div>
    </div>
  );
};

const SoftSkills = ({data}) => {
  return (
    <div className="soft-skills-main">
      <div className="soft-skills-items">
        {
          data?.map((soft)=>(
            <SoftSkillItem progress={80} softSkill={soft}/>
          ))
        }
       
      </div>
      {/* <div className="description">
        Archie excels in problem-solving, quickly identifying issues and
        developing effective solutions. His strong communication skills ensure
        clear and concise information exchange. He efficiently manages time,
        prioritizing tasks to meet deadlines. As a leader, he inspires and
        motivate his team, fostering a collaborative environment.
      </div> */}
    </div>
  );
};

const SoftSkillItem = ({ softSkill }) => {
  const getProgressValue = (level) => {
    switch (level) {
      case 'Beginner':
        return 30;
      case 'Intermediate':
        return 60;
      case 'Expert':
        return 90;
      default:
        return 0; // Default progress if level is not specified
    }
  };
  return (
    <div className="soft-skill-item">
      <div className="skill">
        <img src={bulb} alt="problem-solving"></img>
        <span>{softSkill?.softSkillName || ""}</span>
      </div>
      <ProgressBar progress={getProgressValue(softSkill?.softSkillLevel || "")} />
      <div className="remarks">{softSkill?.softSkillLevel || ""}</div>
    </div>
  );
};

const Languages = ({ data }) => {
  return (
    <Stack direction="row" flexWrap="wrap" gap={2} className="languages">
      {data?.map((lan, index) => (
        <LanguageItem key={index} language={lan} />
      ))}
    </Stack>
  );
};

const LanguageItem = ({ language }) => {
  const getRatingValue = (level) => {
    switch (level) {
      case 'Fluent':
        return 2;
      case 'Intermediate':
        return 3;
      case 'Advanced':
        return 4;
      default:
        return 1; // Default rating if none match
    }
  };
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{ width: { xs: '100%', sm: '45%', md: '30%', lg: '40%' } }}
      className="language-item"
    >
      <span style={{ fontWeight: 500, fontSize: '16px' }}>{language?.languageName || ""}</span>
      <Rating
      name="language-rating"
      readOnly
      value={getRatingValue(language?.languageLevel)} // Set the rating value dynamically
      icon={<CircleIcon style={{ color: "#062B6E" }} />}
      emptyIcon={<CircleIcon style={{ color: "#A5B3CB" }} />}
      sx={{ fontSize: '20px' }}
    />
      <span style={{ fontSize: '14px', color: '#666' }}>{language?.languageLevel || ""}</span>
    </Stack>
  );
};

const IconContainer = ({ children }) => {
  return <span style={{ margin: "0 4px", display: "flex" }}>{children}</span>;
};

const Interests = ({ data }) => {
  return (
    <div className="interests">
      {data.map((item) => (
        <span>{item}</span>
      ))}
    </div>
  );
};

export default CandidateInfo;