import axios from "axios";
import React from "react";
import { LinkedIn } from "react-linkedin-login-oauth2";
import { afterLogin } from "../../Common/flowAfterLogin";

export const LinkedInSso = () => {
  async function getLinkedInToken(code) {
    console.log("linkedin 1==>", code);
    try {
      let obj = {
        code: code,
        redirect_url: `${window.location.origin}/linkedin`,
        // redirect_url: `https://${process.env.BASE_URL}/linkedin`,
      };
      // get token for linkedin
      const urlEndpoint = "/candidate/login/getLinkedinToken";
      const MailObj = await axios
        .post(urlEndpoint, obj)
        .then((res) => res.data);
      console.log("linkedin 2==>", MailObj);

      if (MailObj?.MailObj?.email) {
        // console.log("email=>", MailObj?.MailObj?.email);
        // sessionStorage.setItem("email", MailObj?.MailObj?.email);
        localStorage.setItem("email", MailObj?.MailObj?.email);
        afterLogin();
      } else throw new Error("login error");

      // check if user is authenticated in our system
      // const linkedinLogins = "/candidate/login/linkedin";
      // const val = await axios
      //   .post(linkedinLogins, MailObj)
      //   .then((res) => res.data);

      // console.log("linkedin 3==>", val, MailObj?.email);
      // if (res) {
      //   sessionStorage.setItem("email", credentials?.email);
      //   afterLogin();
      // }
    } catch (err) {
      console.log("linkedin error==>", err);
    }
  }
  return (
    <LinkedIn
      clientId="77xh05iaataril"
      redirectUri={`${window.location.origin}/linkedin`}
      onSuccess={(code) => {
        console.log("code==>", code);
        getLinkedInToken(code);
      }}
      onError={(error) => {
        console.log("error==>", error);
      }}
    >
      {({ linkedInLogin }) => (
        <button type="button" onClick={linkedInLogin} className="butn linkedIn">
          <img className="logoGoogle" src={"./icons/linkedin.svg"} />
          Sign in with LinkedIn
        </button>
      )}
    </LinkedIn>
  );
};
